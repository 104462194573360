<template>
  <div class="page-wrapper">
    <router-view id="content-view-inner" />
  </div>
</template>

<script>
export default {
  name: "Checkout",
}
</script>
